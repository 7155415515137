var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppLayout',[_c('TableHeader',{attrs:{"table-options":_vm.tableOptions,"title":"Laboranti"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('Button',{attrs:{"color":"secondary","loading":_vm.isSyncInProgress},on:{"click":_vm.synchronize}},[_vm._v("Synchronizovat")])]},proxy:true}])}),_c('div',{staticClass:"table-wrapper"},[_c('Table',{attrs:{"table-options":_vm.tableOptions},scopedSlots:_vm._u([{key:"button",fn:function(ref){
var item = ref.item;
return [(!_vm.isActive(item))?_c('Button',{attrs:{"color":"secondary","outlined":""},on:{"click":function () { return _vm.invite(item); }}},[_vm._v(" Přizvat ")]):_vm._e()]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('ActionsWrapper',[_c('RoundButton',{attrs:{"to":{
              name: _vm.Routes.LaborerDetail,
              params: {
                id: item.id,
              },
            },"icon":"pencil"}})],1)]}},{key:"surname",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
            name: _vm.Routes.LaborerDetail,
            params: {
              id: item.id,
            },
          }}},[_vm._v(" "+_vm._s(item.surname)+" ")])]}},{key:"status",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t(("laborers." + (value.toLowerCase()))).toUpperCase())+" ")]}}])})],1),_c('LaborerInviteModal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }