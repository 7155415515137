



























































import { Component, Vue } from "vue-property-decorator";
import AppLayout from "../components/commons/layouts/AppLayout.vue";
import Table from "../components/commons/tables/Table.vue";
import TableHeader from "../components/commons/tables/TableHeader.vue";
import ActionsWrapper from "../components/commons/tables/ActionsWrapper.vue";
import Button from "../components/commons/inputsAndControls/Button.vue";
import RoundButton from "../components/commons/inputsAndControls/RoundButton.vue";
import { TableOptions, TableRefreshPayload } from "../types";
import {
  Laborer,
  LaborersFilterEnum,
  LaborerStatusEnum,
  SyncLaborersMutation,
  SyncLaborersMutationVariables,
} from "../types/gqlGeneratedPrivate";
import { TablesActions, TablesNamespaces } from "../store/modules/tables";
import Modal from "../components/commons/Modal.vue";
import { Action } from "vuex-class";
import { EventBus, Routes } from "../enums";
import { eventBus } from "../utils/eventBus";
import LaborerInviteModal from "../components/app/laborers/LaborerInviteModal.vue";
import { api } from "../api";
import { apiClient, alerts } from "../utils";

@Component({
  components: {
    AppLayout,
    Button,
    Modal,
    RoundButton,
    Table,
    TableHeader,
    ActionsWrapper,
    LaborerInviteModal,
  },
})
export default class LaborersPage extends Vue {
  Routes = Routes;

  isSyncInProgress = false;

  @Action(TablesActions.Refresh, {
    namespace: TablesNamespaces.LaborersTable,
  })
  refreshTable!: (payload: TableRefreshPayload) => Promise<void>;

  tableOptions: TableOptions<Laborer, LaborersFilterEnum> = {
    defaultSortBy: ["surname"],
    headers: [
      {
        text: "Jméno",
        sortable: true,
        align: "start",
        value: "firstname",
      },
      {
        text: "Příjmení",
        sortable: true,
        align: "start",
        value: "surname",
      },
      {
        text: "Status",
        sortable: true,
        align: "start",
        value: "status",
      },
      {
        text: "Email",
        sortable: true,
        align: "start",
        value: "username",
      },
      {
        text: "",
        sortable: false,
        value: "button",
        width: "13ch",
      },
      {
        text: "",
        sortable: false,
        value: "actions",
      },
    ],
    namespace: TablesNamespaces.LaborersTable,
  };

  isActive(laborer: Laborer) {
    return laborer.status === LaborerStatusEnum.Active;
  }

  async synchronize(): Promise<void> {
    this.isSyncInProgress = true;
    const { data } = await apiClient.callGraphqlPrivate<
      SyncLaborersMutation,
      SyncLaborersMutationVariables
    >({
      ...api.laborers.sync,
    });
    if (data) {
      if (data.syncLaborers) {
        alerts.addSuccessAlert(
          "LABORERS_SYNC",
          "Synchronizace proběhla úspěšně"
        );
      } else {
        alerts.addErrorAlert(
          "LABORERS_SYNC",
          "Synchronizace proběhla s negativní odpovědí"
        );
      }
      this.refreshTable({
        namespace: TablesNamespaces.LaborersTable,
      });
    }
    this.isSyncInProgress = false;
  }

  async invite(laborer: Laborer): Promise<void> {
    eventBus.$emit(EventBus.LaborerInviteModal, laborer);
  }
}
